:root {
    --default-color: #212121;
    --primary-color: #2962ff;
    --success-color: #1eb842;
    --danger-color: #c30;
    --secondary-color: #6c757d;
    --border-color: #d9d9d9;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

button {
    text-transform: capitalize;
}

.ck-editor__editable_inline {
    min-height: 400px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.nav-menu-link li.MuiListItem-root {
    transition: all 0.5s;
    transform-origin: 0;
}

.nav-menu-link.active li.MuiListItem-root {
    color: white;
    background-color: var(--primary-color);
    scale: 1.05;
    background-color: rgba(25, 118, 210, 0.08);
}

.nav-menu-link.active .MuiListItemIcon-root {
    color: white;
}

.nav-menu-link li.MuiListItem-root:hover {
    scale: 1.05;
}

.table-cell--image {
    max-width: 50px;
    max-height: 50px;
    height: 50px;
    width: 50px;
    text-align: center;
    border: 1px solid #ebecf0;
    vertical-align: middle;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.table-cell--image .box-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
